import { getOrCreateDataObject } from "o365-dataobject";
import { getOrCreateProcedure } from "o365-modules";

export default class TagRecognizer {
    readonly recognizerText: RecognizerFullText;
    readonly sessionId: number;

    patterns: ParsedPattern[];

    depth = 2;

    constructor(pText: string, pPatterns: Pattern[]) {
        this.recognizerText = new RecognizerFullText(pText); 

        const parsedPatters: ParsedPattern[] = [];

        for (const pattern of pPatterns) {
            parsedPatters.push({
                $value: pattern.value,
                regex: this.getRegexFromPattern(pattern.value)
            })
        }

        this.patterns = parsedPatters;

        this.sessionId = getSessionId();
    }

    async getMatches() {
        const potentialMatches = this.getPotentialMatches({
            distinct: true
        });

        if (potentialMatches.length == 0) {
            return [];
        }

        const dataObject = this._getObjectMatchesDataObject();
        await dataObject.recordSource.bulkCreate(potentialMatches.map((tag) => {
            return {
                Tag: tag.name,
                Session_ID: this.sessionId,
            }
        }));

        const result = await (this._getObjectConnectProc()).execute({ Session_ID: this.sessionId });
        const tags = result.Table as { Tag: string, Object_ID?: number, }[];
        return tags;
    }

    getPotentialMatches(pOptions?: {
        distinct?: boolean
    }) {
        const isMatch = (pText: string) => {
            for (const pattern of this.patterns) {
                if (pattern.regex.test(pText)) {
                    return true;
                }
            }
            return false
        }
        
        let result: {
           name: string,
           lines: RecognizerLine[]
        }[] = [];
        for (const line of this.recognizerText.lines) {
            let traverser = line.getTraverser();
            let potentialTagName = '';
            while(traverser.line && traverser.depth < this.depth) {
                if (traverser.depth > 0) { potentialTagName += ' '; }
                potentialTagName += traverser.line.text; 
                if (isMatch(potentialTagName)) {
                    result.push({ name: potentialTagName, lines: traverser.path });
                }
                traverser = traverser.getNext();
            }
        }

        if (pOptions?.distinct) {
            const hits = new Set<string>();
            result = result.filter((tag) => {
                if (hits.has(tag.name)) { return false; }
                hits.add(tag.name);
                return true;
            });
        }
        return result
    }

    getRegexFromPattern(pPattern: string) {
        const regexPattern = pPattern
            .replace(/%/g, '.')
            .replace(/ /g, '\\s')  
            .replace(/-/g, '\-');   

        this.depth = Math.max(this.depth, regexPattern.split('\\s').length);        
        
        return new RegExp(`^${regexPattern}$`);
    }


    private _getObjectMatchesDataObject() {
        const dataObject = getOrCreateDataObject({
            id: 'o_dsObjectMatches',
            viewName: 'atbv_Arena_DocumentsObjectsMatches',
            fields: [
                { name: 'Tag' },
                { name: 'Object_ID' },
                { name: 'Session_ID' }
            ],
        });
        dataObject.recordSource.whereClause = `[Session_ID] = ${this.sessionId}`;

        return dataObject;
    }

    private _getObjectConnectProc() {
        const proc = getOrCreateProcedure<{ Session_ID: number}>({
            id: 'procConnectObjectTagMatches',
            procedureName: 'astp_Arena_ConnectObjectTagMatches'
        });

        return proc;
    }
}

type Pattern = {
    value: string
};

type ParsedPattern = {
    $value: string,
    regex: RegExp
}

class RecognizerFullText {
    readonly text: string

    lines: RecognizerLine[] = [];

    constructor(pText: string) {
        this.text = pText;

        const lines = this.text.split('\n');
        for (let i=0; i < lines.length; i++) {
            this.lines.push(new RecognizerLine({
                index: i,
                text: lines[i],
                source: this
            }));
        }
    }
}

class RecognizerLine {
    $source: RecognizerFullText;
    readonly index: number;
    readonly text: string;

    constructor(pOptions: {
        index: number,
        text: string,
        source: RecognizerFullText
    }) {
        this.$source = pOptions.source;
        this.index = pOptions.index,
        this.text = pOptions.text;
    }

    getTraverser() {
        const origin = this;

        const generateMonad = (pLine: RecognizerLine, pPath: RecognizerLine[], pDetph = 0) => {
            const monad = {
                $source: origin,
                line: pLine,
                path: pPath,
                depth: pDetph,
                getNext() {
                    const nextLine = origin.$source.lines[pLine.index + 1];
                    return generateMonad(nextLine, [...pPath, nextLine], pDetph + 1);
                }
            };

            return monad;
        };

        return generateMonad(this, [this]);
    }
}


/**
 * Get or create an UID for the Object atbl_Arena_DocumentsObjectsMatches
 */
function getSessionId() {
    const max = 2147483647;
    const UID = Math.floor(Math.random() * (max + 1));
    return UID;
}

export { TagRecognizer }; 